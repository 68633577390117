 body {
   background-color: rgb(254, 252, 247);
 }
 
 #root {
   /* max-width:375px; */
   margin: auto;
   overflow-x: hidden;
   overflow-y: auto;
   background-color: #FFFFFF;
   position: relative;
   min-height: 100vh;
 }


 iframe {
   display: none;
 }