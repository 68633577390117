.service {
  padding-bottom: 80px;
  h3{
    font-size: 18px;
    color: #0A2851;
  }
  &-card {
    background: #ffffff;
    text-align: center;
    border: 1px solid rgba(174, 185, 199, 0.2);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 12px;
    flex: 1;
  }
}
