a {
  text-decoration: unset !important;
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}

.pointer {
  cursor: pointer;
}

.displayNone {
  display: none;
}
.btn-gradient {
  font-size: 18px;
  height: 50px;
  background-image: linear-gradient(5deg, rgb(9, 62, 180), rgb(19, 200, 86));
  width: 100%;
  border: 4px solid #cae0cf;
  border-radius: 35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  cursor: pointer;
  position: relative;
  z-index: 4;
}
.header-gradient {
  font-size: 18px;
  height: 50px;
  background-image: linear-gradient(5deg, rgb(9, 62, 180), rgb(19, 200, 86)) !important;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
}