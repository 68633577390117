.wallet {
  &-balance {
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #0a2851;
  }
  &-money {
    font-size: 20px;
    font-weight: 500;
    color: $main;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 6px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 22px;
  }
  input {
    text-align: center;
  }

  .success{
    > span {
      color: green !important;
    }
    background-color: #fff !important;
    border-color: green !important;
  }

  .warning{
    > span {
      color: orange !important;
    }
    background-color: #fff !important;
    border-color: orange !important;
  }

  .error{
    > span {
      color: red !important;
    }
    background-color: #fff !important;
    border-color: red !important;
  }
}
