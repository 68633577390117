// design system
// Colors
// Common
$main: #0073ff;
$border-gray: #9eaabb;
$white: #ffffff;
$red: #ff2e00;
$orange: #ff8314;
$orange-200: rgba(255, 131, 20, 0.2);
$yellow: #ffdf36;
$purple: #4e2ecf;

// Color #1
$color1-main: #36ffb5;
$color1-bg-primary1: #06052a;
$color1-bg-primary2: #141432;
$color1-bg-sub1: #1b1a43;
$color1-bg-sub2: #211c48;
$color1-bg-sub3: #211c48;
$color1-text1: #141432;
$color1-blue: #09d1fe;
$color1-gray0: #616161;
$color1-gray1: #737373;
$color1-gray2: #acacac;
$color1-gray3: #d9d9d9;
$color1-gray4: #f5f5f5;
$color1-orange: #ff9d46;

// Color #2
$color2-main: #e26b0f;
$color2-sub6: #00bf13;
$color2-sub2: #141432;
$color2-text2: #141432;
$color2-gray1: #f8f8f8;
$color2-gray2: #f1f1f1;
$color2-gray3: #fbfbfb;

// Color #3
$color3-main: #047b73;
$color3-secondary: #ffba52;
$color3-blue: #5479bb;
$color3-blue-200: #f2f7ff;
$color3-text: #222222;
$color3-red: #ed3c37;
$color3-gray: #f7f7f7;

// customize
$primary: $color3-main;
$secondary: $color3-secondary;
$light: $color2-gray1;

$font-family-base: "Barlow";
$line-height-base: 1.25;
$font-size-base: 1rem;

$body-color: $color3-text;
$body-bg-color: $white;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// additional class
//reset
p {
  margin: 0;
  padding: 0;
}

// fonts
.fw-semibold {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-7 {
  font-size: 0.9375rem;
}

.fs-8 {
  font-size: 0.875rem;
}

// colors
.bg-blue {
  background-color: $color3-blue !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-orange-200 {
  background-color: $orange-200 !important;
}

.bg-gray {
  background-color: $color3-gray !important;
}

.bg-success {
  background-color: rgba(0, 191, 19, 0.1) !important;
  color: $color2-sub6 !important;
}

.bg-warning {
  background-color: rgba(255, 131, 20, 0.1) !important;
  color: $orange !important;
}

.bg-error {
  background-color: rgba(255, 46, 0, 0.1) !important;
  color: $red;
}

.text-blue {
  color: $color3-blue !important;
}

.text-gray {
  color: $color1-gray2 !important;
}

.text-orange {
  color: $orange !important;
}

// components
.card {
  background: $white !important;
  border: 1px solid rgba(0, 0, 0, 0.04) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  border-radius: 6px !important;
}

.card-header {
  background: none;
  padding: 20px 9px !important;
  border: none;
}

.img-rounded {
  width: 40px;
  height: 40px;
  background: $color2-gray3;
  box-sizing: border-box;
  border-radius: 53.3333px;
  &--vertical {
    width: 76px;
    height: 76px;
  }
}

.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.badge {
  position: static !important;
}

.border-bottom-active {
  border-bottom: 2px solid $primary;
}

.border-top-blue {
  border-top-color: $color3-blue-200;
}

.btn-outline-blue {
  background-color: $color3-blue-200;
}

// utils
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-vertical {
  display: flex;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.text-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.img-logo {
  width: 150px;
}

.btn-outline {
  border: 1px solid $main !important;
  background-color: #fff;
  border-radius: 4px;
  span {
    font-weight: 500;
    color: $main;
  }
}

.btn-filled {
  background-color: $main;
  border-radius: 4px;
  border: 1px solid transparent !important;
  span{
    font-weight: 500;
    color: #fff
  }
  &:hover {
    background-color: $main;
    opacity: .7;
  }
  &:focus{
    background-color: $main;
  }
}
// Animation
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft !important;
}

.bg-img-earth {
  background-image: url("/assets/imagesHome/bg-branch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-earth-full {
  background-image: url("/assets/imagesHome/bg-branch.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.pointer {
  cursor: pointer;
}
// Pages
@import "../Page/Home/index.scss";
// @import "../Page/Index/index.scss";
@import "../Page/Login/index.scss";
@import "../Page/Register/register.scss";
@import "../Page/Home/index.scss";
@import "../Page/Wallet/index.scss";
@import "../Page/ServiceCustomer/index.scss";
@import "../Page/Profile/index.scss";
// @import "../Page/Branch/index.scss";
// @import "../Page/Group/index.scss";
// @import "../Page/Introdure/index.scss";
// @import "../Page/Layout/index.scss";
// @import "../Page/LeaderBoard/index.scss";
// @import "../Page/ListCoinPrice/index.scss";
// @import "../Page/ListPackageBonus/index.scss";
// @import "../Page/ListPackagePurchased/index.scss";
// @import "../Page/Management/index.scss";
// @import "../Page/Notifications/index.scss";
// @import "../Page/Organization/index.scss";
// @import "../Page/Packet/index.scss";
// @import "../Page/PurchaseHistory/index.scss";
// @import "../Page/Recharge/index.scss";
// @import "../Page/Saving/index.scss";
// @import "../Page/Settings/index.scss";
// @import "../Page/Support/index.scss";
// @import "../Page/TransactionRequest/index.scss";
// @import "../Page/WithdrawalHistory/index.scss";
// @import "../Page/Staking/index.scss";
