@import "./main.scss";

.contact-modal .ant-modal,
.contact-modal .ant-modal-content {
  width: 296px !important;
  margin: auto;
  border-radius: 12px !important;
  overflow: hidden;
  .header{
    height: 56px;
    background-color: #0073FF;
    line-height: 56px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  .body{
    padding: 20;
  }
  .footer {
    display: flex;
    align-items: center;
    button {
      border: none;
      border-top: 1px solid rgba(174, 185, 199, 0.2);;
      flex: 1;
      span{
        font-weight: 500; 
        color: #536885;
      }
      a{
        text-decoration: none !important;
      }
    }
  }
  button.ant-modal-close{
    display: none;
  }
}
// .ant-modal-body {
//   height: calc(100vh - 180px);
//   padding: 0 !important;
//   overflow-y: scroll;
//   scrollbar-width: none;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }

.ant-modal-header {
  padding: 0 !important;
  border: none !important;
  background-color: transparent;
  z-index: 1;
  position: relative !important;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-input,
.ant-input:hover,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-input-number:hover {
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid;
  border-color: #9eaabb !important;
  border-radius: 4px;
}

.ant-input-number-group-wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: #222222;
  .ant-input-number,
  .ant-input-number:hover,
  .ant-input-number-focused {
    border: none;
  }
  .ant-input-number-group-addon {
    &:first-child {
      border-left-width: 0;
      border-top-width: 0;
      border-bottom-width: 0;
      border-right: 1px solid #222222;
    }
    &:last-child {
      background-color: #ffffff;
      border: none;
    }
  }
}

.ant-input-group-wrapper-status-error,
.ant-input-number-group-wrapper-status-error {
  border-color: #ff4d4f !important;
  .ant-input-group-addon:first-child {
    border-color: #ff4d4f !important;
  }
  .ant-input-number-group-addon:first-child {
    border-color: #ff4d4f !important;
  }
}

.ant-input-number-group-wrapper {
  display: block;
}

.change_language-selected {
  .ant-select-dropdown,
  .ant-select-item-option-selected {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-item-option-active {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
}

.ant-form-item-explain-error {
  text-align: center;
}

.ant-carousel .slick-dots li button {
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

.ant-carousel .slick-dots-bottom {
  bottom: 20px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-large .ant-form-item-label > label {
  height: 20px;
}

.ant-page-header {
  background-color: $main;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    color: white;
  }
  &-back {
    position: absolute;
    left: 25px;
  }
}

.ant-collapse:not(.ant-collapse-borderless) {
  background: #ffffff;
  border: 1px solid rgba(174, 185, 199, 0.2);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  & > .ant-collapse-item{
    border-color: rgba(174, 185, 199, 0.2) ;
  }  
}

.ant-collapse {
  &-extra{
    margin: auto 0 ;
    margin-left: auto;
  }
}

.ant-radio-button-wrapper{
  border-radius: 4px !important;
  background: #EFF4F8;
  border: none;
  font-size: 12px;
  &:first-child{
    border: none;
  }
  &::before {
    opacity: 0;
  }
  &-checked {
    background-color: $main;
  }
}

.ant-btn {
  &:disabled{
    background-color: rgba(174, 185, 199, 0.2);
    span {
      color: #AEB9C7;
    }
  }
}


.ant-steps{
  &-item{
    &-icon{
      border: 1px solid #657C97 !important;
      > span {
        color: #657C97 !important;
        font-size: 14px;
        font-weight: 600;
      }
    }
    &-title{
      color: #657C97 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .ant-steps-item-description{
    padding-bottom: 0 !important;
  }
  .ant-steps-item-tail{
    &::after{
      background-color: #9EAABB !important;
    }
  }
}

.ant-steps-item-process{
  .ant-steps-item-icon{
    border: 1px solid transparent !important;
  }
  .ant-steps-icon{
    color: #fff !important;
  }
  .ant-steps-item-title{
    color: $main !important;
    font-weight: 500;
  }
}

.ant-upload {
  width: 100%;
}