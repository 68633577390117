.nav {
    border-top: 1px solid rgba(174, 185, 199, 0.2);
  &__item {
    text-align: center;
    cursor: pointer;
    svg {
        transition: all .2s ease;
        margin-bottom: 7px;
    }
    &:hover > svg {
        transform: scale(1.2);
    }
  }
}
