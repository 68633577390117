.profile {
  padding-bottom: 100px;
  &__button {
    border: none !important;
    box-sizing: border-box !important;
    color: #536885 !important;
    font-weight: 600;
    height: 40px;
    border-radius: 4px;
    background: rgba(174, 185, 199, 0.2) !important;
  }
  &__warning {
    padding: 9px 16px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 2px;
    color: var(---main-li-color);
    font-size: 14px;
    line-height: 22px;
    svg {
      margin-right: 10px;
    }
    margin-bottom: 60px;
  }
  &__text {
    color: var(---main-li-color);
    letter-spacing: 0.01em;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  &__title {
    font-size: 16px;
    line-height: 22px;
    color: var(--main-li-color);
  }
  &__boxLink {
    background: var(--main-four-color);
    border-radius: 6px;
    padding: 13px 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-align: center;
    color: var(--white-color);
    margin-top: 12px;
    margin-bottom: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__top {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100px;
    background-image: url("/assets/imagesHome/factory_header.png");
    .factory__top__avatar {
      position: absolute;
      top: 50%;
    }
    &__title {
      position: absolute;
      top: 20%;
      padding-top: 10px;
      color: white;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  &__image-identify {
    width: 100%;
    /* Color #1/Gray #4 */

    background: #f5f5f5;
    /* Color #1/Gray #3 */

    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
    .img-name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #047b73;
    }
    .img-button {
      border: none;
    }
  }
  .packet__empty__list {
    background: rgba(255, 131, 20, 0.1);
    color: #ff8314;
  }
  .management__box__hr {
    height: 50px;
  }
}

.tag-status {
  border-radius: 8px;
  height: 22px;
  font-size: 10px;
  font-weight: 400;
}

p {
  font-size: 14px;
}

.my-loan {
  margin: 20px;
  border-radius: 12px;
  // padding: 10px;
  // border: 1px solid ;
  h3{
    font-size: 18px;
    font-weight: 600;
    color: #0A2851;
  }
  label {
    color: #536885;
    font-size: 12px;
    font-weight: 400 ;
  }
  p{
    font-size: 14px;
    font-weight: 600;
    color: #073764;
  }
  &-types {
    display: flex;
    flex-direction: column;
    gap: 30px;
    & > div {
      border-bottom: 1px solid rgba(174, 185, 199, 0.2);
      h3 {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .balance {
        font-size: 20px;
        font-weight: 400;
        color: $main;
        text-align: center;
        margin-bottom: 4px;
      }
    }
  }
}

.loan-status {
  padding: 20px;
  padding-bottom: 80px;
  .ant-steps-item-title{
    font-size: 18px;
  }
  &-infomation {
    display: flex;
    flex-direction: column;
    gap: 30px;
    & > div {
      border-bottom: 1px solid rgba(174, 185, 199, 0.2);
      h3 {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .balance {
        font-size: 20px;
        font-weight: 400;
        color: $main;
        margin-bottom: 4px;
      }
    }
  }
}

.account-info {
  padding: 20px;
  padding-bottom: 80px;
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-form-item-label > label {
    color: #536885;
    font-size: 14px;
    font-weight: 600;
  }
  .ant-upload-list-item {
    border-radius: 4px !important;
  }
  .ant-upload-disabled{
    > button, > button * {
      border-color:  #bdc3c7 !important;
      color:  #bdc3c7 !important;
      fill: #bdc3c7 !important;
      cursor:not-allowed;
    }
    user-select: none;
    opacity: .8;
  }
}
